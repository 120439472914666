import logo from './logo.jpeg'

export const Nav = () => {
    return (
        <div className='flex h-20 bg-slate-700 content-center'>
            <img src={logo} alt='logo' />
            <h3 className='text-white ml-2 my-auto px-4 text-xl w-full'>Campaign Analytics</h3>
            {/* <a href="https://<tableau-server-name>/auth/logout">logout</a> */}

        </div>
    )
}