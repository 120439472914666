// import { useAuth } from ".."
import React, { useRef } from "react";
import { Nav } from "../components/Nav"
import { config } from "../firebase"
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from 'firebase/remote-config'

const getFeatureFlagVariable = async (flagName) => {
    try {
        await fetchAndActivate(config)
        const value = getValue(config, 'tableu_embed_url')
        console.log(value)
        return value.asString()
    } catch (error) {
        console.log(error)
    }
}
export const Reports = () => {

    const viz = useRef(null)
    const [src, setSrc] = React.useState('')
    console.log(src)
    React.useEffect(() => {
        getFeatureFlagVariable('tableu_embed_url').then((value) => {
            setSrc(value)
        })
    }, []);

    // function exportToPDF() {
    //     viz.current.displayDialogAsync("export-pdf");
    //     // console.log(viz)
    // }

    return (
        <div>

            <Nav />
            {/* <button onClick={() => exportToPDF()} style={{ color: 'black' }} type="button" id="export-pdf">Export to PDF</button> */}

            <tableau-viz ref={viz} src={'https://prod-uk-a.online.tableau.com/t/fourthfloor/views/SquareEnixAnalyticsDashboardv1/PaidOverview'} style={{ width: '100vw', height: '100vh' }}
                hide-tabs toolbar='hidden' ></tableau-viz>
            {/* {
                src ?  : <div>loading</div>
            } */}
        </div >
    )
}

