import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: 'AIzaSyD24ze92iR3BF28BE_pWsWERUizw7Yqe5A',
  authDomain: "tableu-portal.firebaseapp.com",
  projectId: "tableu-portal",
  storageBucket: "tableu-portal.appspot.com",
  messagingSenderId: "214748560345",
  appId: "1:214748560345:web:aa96f5dc3d8389803a77c8",
  measurementId: "G-3SY41SGM42"
};

export const app = initializeApp(firebaseConfig);
export const config = getRemoteConfig(app);