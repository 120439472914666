import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Reports } from './pages/Reports';

createRoot(document.getElementById("root")).render(
  <Reports />
);

reportWebVitals();
